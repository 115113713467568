body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: #D9B765;;
}

::selection {
  color: black;
  background: #D9B765;
}
