.App {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    min-height: 100vh;
}

.Header {
    display: flex;
    width: 100%;
    height: 100px;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
}

.header-items {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 100px;
}

.mobile-nav {
    background-color: #010F11;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transition: all .5s ease-in-out;
    height: 120px;
}

.mobile-nav:target {
    height: 120px;
    transition: height 5s linear;
}

.nav-logo {
    padding: 10px;
    padding-left: 20px;
}

.center {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
}

.Nav-Items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    margin-left: 10px;
    margin-right: 10px;
}

.page-height {
    height: 100vh;
}

.nav-order-button {
    color: #D9B765;
    background-color: white;
    border: 1px solid #D9B765;
    padding: 8px 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;

}

.nav-order-button:hover {
    color: white;
    background-color: #D9B765;

}


.nav-item-button:hover {
    color: #D9B765;
}


.base-main {
    height: calc(100vh - 150px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    /*box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);*/
    color: white;
}

.welcome-screen {
    background-image: url("./static/spiceBackground.jpg");
}

.venue-welcome {
    background-image: url("./static/event.jpg");
}


.main-text {
    display: flex;
    font-family: "Roboto", serif;
    color: black;
    font-size: 1.3em;
    justify-content: center;
    margin-top: 25px;
    line-height: 200%;
    text-align: center;
    width: 50%;

}

.darken {
    background-color: rgba(0, 0, 0, 0.5);
}


.our-story-image {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0;
    max-height: 250px
}

.card:hover {
    box-shadow: 4px 8px 12px 4px rgba(0, 0, 0, 0.2);
}


.card-title {
    font-size: 1.2em;
}

.two-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.down-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    min-width: 100px;
}

.bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.larger {
    flex: 2;
    padding-right: 25px;
}

.image {
    width: 100%;
    min-height: 400px;
    max-height: 100%;
    object-fit: cover;
}

.big-text {
    font-size: 3em;
    text-align: center;
}

.fade-in {
    animation: fadeIn running 5s;
    -webkit-animation: fadeIn running 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.footer {
    min-height: 300px;
    background-image: url("./static/spiceBackground.jpg");
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    color: white;
    width: 100%;
    display: flex;

}

.mobile-button {
    width: 80%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.35em 1.2em;
    border: 0.1em solid #000000;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.12em;
    box-sizing: border-box;
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    transition: all 0.2s;
    background-color: #D9B765;
}

.mobile-order {
    display: none;
}

.mobile-menu {
    display: none;
}
.our-story {
    width: 50%;
}


@media only screen and (max-width: 768px) {
    html, body {
        margin: 0;
        padding: 0;
    }

    .header-items {
        width: 100%;
    }

    .Nav-Items {
        display: none;
    }

    .main-text {
        width: 90%;
        line-height: 120%;
        margin: 20px;
    }

    .mobile-order {
        margin-top: 20px;
        min-height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .mobile-menu {
        margin-right: 20px;
        display: flex;

    }

    .welcomeButton {
        width: 75vw;

    }

    .our-story {
        width: 100%;
        padding: 5%;
    }

}

.welcomeButton {
    background-color: #D9B765;
    display: inline-block;
    border: none;
    color: black;
    font-size: 25px;
    padding: 20px;
    margin: 5px;
    border-radius: 20px;
    min-width: 300px;
    font-weight: bold;
    text-decoration: none;
}

.welcomeButton:hover {
    box-shadow: 4px 8px 12px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    color: #D9B765;
    cursor: pointer;
}

